
import { Component, Vue,Inject } from "vue-property-decorator";
import { roomList, floorList } from "@/api";

@Component
export default class List extends Vue {
    @Inject() passwordInit!: () => void;
    private active = 0;
    private leased = [];
    private notLeased = [];
    private floor = [];
    private barList: string[] = [];

    created() {
        this.passwordInit();
        this.floorList();
        this.roomList();
    }

    async floorList() {
        const { data } = await floorList();
        const arr: string[] = [];
        this.floor = data;
        data.forEach(({ name }: { name: string }) => {
            arr.push(name[0]);
        });
        this.barList = arr;
    }
    async roomList() {
        const { data } = await roomList();
        this.leased = data.leased;
        this.notLeased = data.notLeased;
    }
    toDetail(id: number) {
        this.$router.push("detail/" + id);
    }
}
